@mixin attr-x($label-prefix, $start, $label-suffix, $increment, $count, $attr, $unit) {
  $attr-list: null;
  @for $i from $start through $count {
    $attr-value: $increment * $i;
    .#{$label-prefix}#{$attr-value}#{$label-suffix} {
      #{$attr}: #{$attr-value}#{$unit};
    }
    $attr-list: append($attr-list, unquote('.#{$attr}-#{$attr-value}'), comma);
  }
  #{$attr-list} {
    //append style to all classes
  }
}

$start: 1;
$increment: 1;
$count: 4;
@include attr-x('padding-', $start, 'em', $increment, $count, 'padding', 'em');
@include attr-x('padding-top-', $start, 'em', $increment, $count, 'padding-top', 'em');
@include attr-x('padding-right-', $start, 'em', $increment, $count, 'padding-right', 'em');
@include attr-x('padding-bottom-', $start, 'em', $increment, $count, 'padding-bottom', 'em');
@include attr-x('padding-left-', $start, 'em', $increment, $count, 'padding-left', 'em');
$start: 1;
$increment: 10;
$count: 10;
@include attr-x('width-', $start, '', $increment, $count, 'width', '%');

.align-center {
  margin-left: auto;
  margin-right: auto;
}

.image-flex .image-component {
  display: flex;
}

// resets and general styles
* {
  -webkit-touch-callout: default;
  color: inherit;
  text-decoration: inherit;
  margin: 0;

  & :focus {
    outline: none;
  }
}

html,
body {
  height: 100%;
  background-color: #f3f3f3;
  margin: 0;
  font-family: Roboto, sans-serif;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

img {
  width: 100%;
}

.btn {
  background-color: #f89063;
  color: #f3f3f3;
  font-family: 'PT Sans Narrow', sans-serif;
  font-weight: bold;
}

.btn:hover {
  background-color: #707070;
  color: #f3f3f3;
}

.center {
  text-align: center;
}

.material-icons,
button,
.clickable {
  cursor: pointer;
}

.flex-wrap {
  flex-wrap: wrap;
}

img {
  width: 100%;
}

.relative {
  position: relative;
}

.padding-1em {
  padding: 1em;
}

/* Rules for sizing the icon. */
.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}

.material-icons.md-96 {
  font-size: 96px;
}

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54);
}

.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
  color: rgba(255, 255, 255, 1);
}

.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}

section {
  &.hidden {
    display: none;

    &.show {
      &.cart,
      &.orders,
      &.cart-options,
      &.admin {
        display: block;
      }
    }
  }

  &.collapsible {
    height: 0;
    transition: height 1s ease-in-out;
    overflow: hidden;

    &.search input {
      margin-top: 5px;
      text-align: center;
      font-size: 16px;
      min-width: 250px;
    }

    &.expand {
      &.progress {
        height: 100px;
      }

      &.pricing {
        height: 900px;

        @media all and (min-width: 960px) {
          height: 650px;
        }

        @media all and (min-width: 1280px) {
          height: 400px;
        }
      }

      &.contact {
        height: 1200px;

        @media all and (min-width: 714px) {
          height: 700px;
        }
      }

      &.search {
        height: 40px;

        @media all and (min-width: 960px) {
          height: 40px;
        }
      }

      &.sizer {
        height: 130px;

        @media all and (min-width: 960px) {
          height: 130px;
        }
      }

      &.account {
        height: 330px;

        @media all and (min-width: 643px) {
          height: 194px;
        }
      }

      &.cart {
        height: 300px;

        @media all and (min-width: 960px) {
          height: 300px;
        }
      }

      &.admin {
        height: 900px;

        @media all and (min-width: 960px) {
          height: 300px;
        }
      }
    }
  }
}

.btn-social button,
.header-icons a {
  margin-right: 12px;
}

.profile-image {
  width: 100px;

  img {
    border-radius: 50px;
    width: 100%;
  }
}

.button-orange.mat-raised-button {
  //font-family:"PT Sans Narrow", sans-serif;
  //background-color: #f89063;
  //color: #f3f3f3;
  margin: 1em;
}
